<!--
<div class="p-4 block sm:hidden sticky bottom-10 z-20 flex justify-end" *ngIf="accountUser">
    <button (click)="selectPage()" class="w-fit p-2 bg-blue-600 rounded-full">
        <svg class="text-white flex-shrink-0 h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
    </button>
</div>
-->

<!-- NOTE ease-in-out is not working due to hidden/block -->
<div #footerContainer
      class="block sm:hidden bg-white mt-auto z-20 border-t border-gray-200 transform transition-transform duration-300 ease-in-out"
       [ngClass]="{
          'pb-5': isIOS,
          '-translate-y-0': showMobile,
          'translate-y-full': !showMobile,
          'hidden': !showMobile,
          'block': showMobile,
        }">

    <div class="px-2 pt-1 pb-2 flex items-center justify-between w-full">

        <button
          (click)="selectPage('hubs')"
          [routerLink]="['/hubs']"
          class="flex-1 group px-3 py-1 rounded-md">
              <!--
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="justify-self-center flex-shrink-0 w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
                </svg>
              -->
              <div class="w-full flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      class="justify-self-center flex-shrink-0 w-7 h-7"
                      [ngClass]="selected == 'hubs' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6" />
                </svg>
              </div>
              <div class="w-full flex justify-center tracking-tighter font-light"
                   [ngClass]="selected == 'hubs' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
                    style="font-size: 9px; margin-top: 2px;">{{ 'Hubs' | translate }}</div>
        </button>

        <button
          class="flex-1 group px-3 py-1 rounded-md"
          (click)="selectPage('jobs')"
          [routerLink]="['/jobs']"
          style="font-size: 10px">

          <div class="w-full flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="justify-self-center flex-shrink-0 w-7 h-7"
                [ngClass]="selected == 'jobs' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
            </svg>
          </div>
          <div class="w-full flex justify-center font-light"
            [ngClass]="selected == 'jobs' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
            style="font-size: 9px; margin-top: 2px;">{{ 'Jobs' | translate }}</div>
        </button>

        <!--
        <a [routerLink]="['/jobs']" (click)="selectPage()" class="grid justify-center hover:bg-gray-50 group px-3 py-1 font-medium rounded-md" [ngClass]="selectedPage=='jobs' ? 'text-blue-600' : 'text-gray-700'" style="font-size: 10px">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="justify-self-center flex-shrink-0 w-7 h-7"
                [ngClass]="selectedPage=='jobs' ? 'text-blue-600' : 'text-gray-400'">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
            </svg>
            <div>Jobs</div>
        </a>

        <a [routerLink]="['/companies']" (click)="selectPage()" class="grid justify-center text-gray-700 hover:bg-gray-50 group px-3 py-1 font-medium rounded-md" style="font-size: 10px">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="justify-self-center text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-7 w-7">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
            </svg>
            <div>Companies</div>
        </a>

        <a [routerLink]="['/search']" (click)="selectPage()" class="inline-block sm:hidden text-gray-700 hover:bg-gray-50 group px-3 py-1 text-sm font-medium rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0  h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
        </a>

        <a *ngIf="accountUser" [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/connections']" (click)="selectPage()" class="text-gray-700 hover:bg-gray-50 group px-3 py-1 text-sm font-medium rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
            </svg>
        </a>
        -->

        <!-- post -->
        <!--
        <button (click)="post()" class="bg-blue-600 p-1 rounded-full">
            <svg class="text-white flex-shrink-0 h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </button>
        -->

        <!--
        <a [routerLink]="['/chat/messages']" class="text-gray-700 hover:bg-gray-50 group px-3 py-1 text-sm font-medium rounded-md">
            <img src="assets/img/logo-hi.png" alt="" class="h-7 w-auto">
        </a>
        -->

        <button [routerLink]="['/']"
              class="flex-1 group px-3 py-1 font-light rounded-md"
              (click)="selectPage('career')">
          <div class="w-full flex justify-center">
            <img src="assets/img/logo-hi.png" alt="" class="h-7 w-auto" [ngClass]="selected == 'career' ? 'grayscale-0' : 'grayscale'">
          </div>
          <div class="w-full flex justify-center tracking-tighter"
              [ngClass]="selected == 'career' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
              style="font-size: 9px; margin-top: 2px;">Career</div>
        </button>

        <!-- notifications -->
        <button
          (click)="selectPage('notifications')"
          [routerLink]="['/me/notifications']"
          [ngClass]="selected == 'notifications' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
          class="flex-1 relative group px-3 py-1 text-sm rounded-md font-light">
          <div class="flex justify-center">
            <div *ngIf="notificationUnreadCount && notificationUnreadCount > 0" class="cursor-pointer absolute bottom-auto left-auto right-6 top-2 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-500 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
                {{ notificationUnreadCount }}
            </div>
            <div>
                <svg class="flex-shrink-0 h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
            </div>
          </div>
          <div class="w-full flex justify-center tracking-tighter"
                style="font-size: 9px; margin-top: 2px;">{{ 'Notifications' | translate }}</div>
        </button>

        <!-- login -->
        <button
          *ngIf="!accountUser"
          (click)="selectPage('profile')"
          [ngClass]="selected == 'profile' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
          [routerLink]="['/login']"
          class="flex-1 group px-3 py-1 text-sm rounded-md font-light">
          <div class="w-full flex justify-center">
            <svg class="flex-shrink-0 h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <div class="w-full flex justify-center"
            style="font-size: 9px; margin-top: 2px;">{{ 'Profile' | translate }}</div>
        </button>
        <!-- profile [routerLink]="['/hi/' + accountUser.getSlug()]"-->
        <button
          *ngIf="accountUser"
          (click)="selectPage('profile'); toggleProfileMenu()"
          [ngClass]="selected == 'profile' ? 'text-blue-600' : 'text-gray-500 hover:text-blue-600'"
          class="flex-1 group px-3 py-1 text-sm rounded-md font-light">
          <div class="w-full flex justify-center">
            <img *ngIf="accountUser.getMainImage().getImg()" src="{{ accountUser.getMainImage().getImg() }}" class="h-7 w-7 object-cover rounded-full" alt="">
            <svg *ngIf="!accountUser.getMainImage().getImg()" class="flex-shrink-0 h-7 w-7"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <div class="w-full flex justify-center tracking-tighter truncate"
            style="font-size: 9px; margin-top: 2px;">{{ accountUser.getFirstName() || ('Profile' | translate) }}</div>
        </button>

    </div>
</div>


<!-- Slide-out profile menu -->
<div *ngIf="accountUser" class="fixed top-0 right-0 z-20 h-screen w-72 bg-white border border-gray-200 py-4 px-4 inset-y-0 transform transition-transform duration-300 ease-in-out"
     [ngClass]="{'-translate-x-0': profileMenuOpen, 'translate-x-full': !profileMenuOpen}">

    <div class="h-full flex flex-col justify-between py-10">
        <div>
            <div class="py-3 flex gap-2">
                <div class="flex-none text-gray-400">
                  <img *ngIf="accountUser.getMainImage().getImg()" src="{{ accountUser.getMainImage().getImg() }}" class="h-10 w-10 object-cover rounded-full" alt="">
                  <svg *ngIf="!accountUser.getMainImage().getImg()" class="flex-shrink-0 h-10 w-10"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <a class="flex-1 truncate" [routerLink]="['/hi/' + accountUser.getSlug()]" (click)="toggleProfileMenu()">
                    <p class="truncate text-sm font-medium text-gray-900" role="none">{{ accountUser.getFullName() }}</p>
                    <p class="truncate text-xs text-gray-500" role="none">{{ accountUser.getEmail() }}</p>
                    <!--
                    <p class="btn-outline-blue-sm mt-2 w-full">View profile</p>
                    -->
                </a>
            </div>

            <div class="flex gap-5 items-center px-4 mt-2 mb-6 text-xs">
                <a [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/connections']" (click)="toggleProfileMenu()">
                    {{ accountUser.getConnectionCount() }}
                    <span class="text-gray-600">connections</span>
                </a>
                <a [routerLink]="['/karma']" (click)="toggleProfileMenu()">
                    <span><i class="bi bi-arrow-up-right-circle text-gray-600"></i> {{ accountUser.getKarma() }} </span>
                    <span class="text-gray-600">points</span>
                </a>

            </div>

            <div class="mt-4 px-4 space-y-5 text-base text-gray-700">
                <a [routerLink]="['/hi/' + accountUser.getSlug()]" (click)="toggleProfileMenu()" class="block flex gap-2 items-center">
                  <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  Profile
                </a>
                <button (click)="shareProfileModule.open(); toggleProfileMenu()" class="block flex gap-2 items-center text-gray-900 hover:text-gray-400">
                  <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                  </svg>
                  Share profile
                </button>
                <a [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/viewers']" (click)="toggleProfileMenu()" class="block flex gap-2 items-center">
                  <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                  Profile viewers
                </a>
                <a [routerLink]="['/service/premium']" (click)="toggleProfileMenu()" class="block flex gap-2 items-center">
                  <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                  </svg>
                  Premium
                </a>
                <a [routerLink]="['/me/settings']" (click)="toggleProfileMenu()" class="block flex gap-2 items-center">
                  <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                  Settings
                </a>
                <a *ngIf="accountUser && accountUser.isStaff()" [routerLink]="['/dc/staff']" (click)="toggleProfileMenu()" class="block flex gap-2 items-center">
                  <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
                  </svg>
                  Staff
                </a>
            </div>
        </div>
        <div class="px-4 space-y-5 text-sm text-gray-700">
            <a [routerLink]="['/terms-of-service']" (click)="toggleProfileMenu()" class="block">Terms of Service</a>
            <a [routerLink]="['/privacy-policy']" (click)="toggleProfileMenu()" class="block">Privacy Policy</a>
            <button class="text-gray-700" (click)="toggleProfileMenu(); logout();">Sign out</button>
        </div>
    </div>
</div>

<!-- Overlay -->
<div class="fixed inset-0 bg-black bg-opacity-50 z-10"
     [ngClass]="{'block': profileMenuOpen, 'hidden': !profileMenuOpen}"
     (click)="toggleProfileMenu()"></div>


<app-modal #shareProfileModule [size]="'md'">
    <app-profile-share *ngIf="shareProfileModule.isOpen()" [profileAccountUser]="accountUser"></app-profile-share>
</app-modal>

<app-modal #postModal [size]="'md'">
    <div class="py-3">
        <!--
        <app-post-form
            *ngIf="postModal.isOpen()"
            (postCreated)="postThanksModal.open(); postModal.close();"></app-post-form>
        -->
    </div>
</app-modal>

<app-modal #postThanksModal [size]="'md'">
    <div class="py-3">
        <div class="flex flex-col items-center justify-center">
            <div class="text-2xl font-bold text-gray-900 mb-3">Post away!</div>
            <div class="text-gray-600 text-center mb-3">Your post is now live on the feed.</div>
            <button *ngIf="accountUser" class="btn-blue-xs" [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/feed/']" (click)="postThanksModal.close()">View</button>
        </div>
    </div>
</app-modal>
